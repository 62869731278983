document.addEventListener("DOMContentLoaded", function () {
  const imageGallery = document.getElementById("image-gallery");

  // Only run the script if the image gallery exists on the page
  if (imageGallery) {
    let currentPage = 1;
    let loading = false;
    let hasNextPage = imageGallery.dataset.hasNext === "true";

    const loadMoreImages = () => {
      if (!hasNextPage || loading) return;

      loading = true;
      currentPage++;
      const loadingElement = document.getElementById("loading");
      if (loadingElement) loadingElement.classList.remove("hidden");

      fetch(`?page=${currentPage}`)
        .then((response) => response.text())
        .then((html) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");
          const newImageGallery = doc.getElementById("image-gallery");

          if (newImageGallery) {
            const newImages = newImageGallery.innerHTML;
            imageGallery.insertAdjacentHTML("beforeend", newImages);

            // Update hasNextPage based on the new data-has-next attribute
            hasNextPage = newImageGallery.dataset.hasNext === "true";
          }

          loading = false;
          if (loadingElement) loadingElement.classList.add("hidden");

          // If there are no more pages, remove the scroll event listener
          if (!hasNextPage) {
            window.removeEventListener("scroll", scrollHandler);
          }
        })
        .catch((error) => {
          console.error("Error loading more images:", error);
          loading = false;
          if (loadingElement) loadingElement.classList.add("hidden");
        });
    };

    const scrollHandler = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 100
      ) {
        loadMoreImages();
      }
    };

    window.addEventListener("scroll", scrollHandler);
  }
});
