// This is the scss entry file
import $ from "jquery";
import "../js/navbar.js";
import "../js/forms.js";
import "../js/components.js";
import "../js/infinite_scroll.js";
import "slick-carousel";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import "../styles/index.scss";

document.addEventListener("DOMContentLoaded", function () {
  $(".slider").slick({
    mobileFirst: true,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: true,
    pauseOnFocus: true,
    pauseOnDotsHover: true,
    swipeToSlide: true,
    touchMove: true,
    nextArrow: $(".carousel .next"),
    prevArrow: $(".carousel .previous"),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,

          slidesToShow: 1,
        },
      },
      // {
      //   breakpoint: 768,
      //   settings: {
      //     arrows: true,

      //     slidesToShow: 1,
      //   },
      // },
    ],
  });
});
